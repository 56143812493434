body {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.top .menu {
    font-size: 1.1rem;
}

@media only screen and (max-width: 320px) {
    body {
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .ui.segments .segment, .ui.segment {
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 640px) {
    body {
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .ui.top.menu .item {
        display: none !important;
    }

    .ui.top.menu .toc.item {
        display: block !important;
    }

    .sellMenu {
        display: none !important;
    }
    .howItWorksMenu {
        display: none !important;
    }

    .buyMenu {
        display: none !important;
    }

    .tokenExchangeMenu {
        display: none !important;
    }

    .settingsMenu {
        display: none !important;
    }

    .mobileSettings {
        padding: 0 !important;
    }

    .ui.horizontal.segments {
        flex-direction: column !important;
    }
}

@media only screen and (min-width: 641px){
    .sellMenuMobile {
        display: none !important;
    }

    .tokenExchangeMenuMobile {
        display: none !important;
    }

    .howItWorksMenuMobile {
        display: none !important;
    }

    .ui.sidebar.visible ~ .pusher {
        width: calc(100% - 260px);
        background-color: white;
    }
}

.menu .ui.dropdown > .text > .flag, .ui.dropdown .menu > .item > .flag {
    margin-right: 0 !important;
}

.menu .ui.dropdown > .dropdown.icon {
    margin-left: 0.7em;
}

.sellSegment .ui.grid > .column:not(.row), .ui.grid > .row > .column {
    padding: 0 !important;
}

@media only screen and (max-width: 767px) {
    .sellSegment .ui.stackable.grid > .eleven.wide.column,
    .sellSegment .ui.stackable.grid > .column:not(.row) {
        padding: 0 !important;
    }

    .marketSegment {
        padding: 0 !important;
    }

    .ui.stackable.grid > .row > .column.marketGrid {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .ui.icon.message > .icon:not(.close) {
        display: none;
    }

    .ui.container .buy-details {
         margin-left: 0 !important;
         margin-right: 0 !important;
    }

    .sellSegment {
        padding: 1em  0 !important;
        margin: 0 !important;
    }
    
    .button-screen { display: none !important }
}

@media only screen and (min-width: 768px) {
    .sellGrid.ui.stackable.vertically.divided.grid {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .buyAndSellDiv {
        padding-right: 2rem;
    }

    .button-mobile { display: none !important }
}

.buy-details .ui.grid > .red.row, .buy-details .ui.grid > .red.column, .buy-details .ui.grid > .row > .red.column,
.ui.centered.grid > .column:not(.aligned):not(.justified):not(.row),
.ui.centered.grid > .row > .column:not(.aligned):not(.justified), .ui.grid .centered.row > .column:not(.aligned):not(.justified) {
    padding: 10px !important;
}

.forceWrap {
    white-space: pre-wrap;
    white-space: -o-pre-wrap;
    white-space: -moz-pre-wrap;
    /*white-space: -hp-pre-wrap;*/
    word-wrap: break-word;
}

.ui.input input:-moz-placeholder { /* Firefox 18- */
    color: #000000;
    opacity: 0.4 !important;
}

.ui.input input::-moz-placeholder { /* Firefox 19+ */
    color: #000000;
    opacity: 0.4 !important;
}

.ui.mini.steps .step, .ui.mini.step {
    color: #C0C0C0;
}

/*.sellSegment.ui.grid > .column:not(.row), .ui.grid > .row > .column {*/
/*padding: 10px !important;*/
/*}*/

/*
.ui.input.error input {
    background-color: red;
    border-color: #E0B4B4;
    color: #9F3A38;
    box-shadow: none
}*/

/*.ui .radio .checkbox .radioLabel {
    color: white;
    font-weight: bold;
    margin: 0.5rem;
}*/

.ui.checkbox label, .ui.checkbox + label {
    color: rgba(0, 0, 0, .87);
    margin: 0.5rem;
    font-weight: bold;
}

.crypto > .ui.checkbox label, .ui.checkbox + label {
    color: white !important;
    margin: 0.5rem;
    font-weight: bold;
}

.ui.radio.checkbox input:checked ~ .box:after, .ui.radio.checkbox input:checked ~ label:after {
    background-color: orange !important;
}

/*
.buy-details .ui.grid > .red.row, .buy-details .ui.grid > .red.column, .buy-details .ui.grid > .row > .red.column, .ui.centered.grid > .column:not(.aligned):not(.justified):not(.row), .ui.centered.grid > .row > .column:not(.aligned):not(.justified), .ui.grid .centered.row > .column:not(.aligned):not(.justified) .insideGrid {
    padding: 0 !important;
}*/

#insideGrid {
    padding: 0 !important;
}

.cryptoCurrencyDropdown {
    margin-bottom: .5em;
    margin-left: 1em;
    font-weight: bold;
}

/*terminal*/

.terminalView {
    margin-top: 0;
    text-align: center !important;
}

@media only screen and (min-width: 740px) {
    .terminalView {
        /* margin: 1% !important; */
        width: 40%;
        text-align: center !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .terminalView .calcTablet {
        margin-left: 1% !important;
        margin-right: 1% !important;
    }
}
.terminalOrange {
    margin: 0.2rem !important;
    background-color: #FFA502 !important;
    color: white !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.terminalBlue {
    margin: 0.2rem !important;
    background-color: #0E566C !important;
    color: white !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}



.calcHeader {
    font-size: 2.3rem !important;
    color: #2b3444 !important;
    margin: 0;
    padding: 0;
    text-align: right !important;
    /*margin-right: 2px !important;*/
    /*margin-bottom: 2px !important;*/
}

.calcFooter {
    font-size: 1.5rem !important;
    color: #2b3444 !important;
}

.calcSubmit {
    background-color: #2fbc3f !important;
}

.calcCancel {
    background-color: #d12e2e !important;
}

.calcAmount {
    text-align: right !important;
}
.calcKey {
    margin: 0.2rem !important;
    /*background-color: #3C3C3B !important;*/
    color: white !important;
}

@media only screen and (min-width: 420px) {
    .terminalFilter {
        max-width: 30% !important;
    }
}

.exchange-notification {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
}

#modal-fix {
    margin-top: 1em;
    top: 0;
    height: 80vh;
}

.blue-button {
    background-color: #2b3444 !important;
    color: white !important;
}

.blue-button:hover {
    opacity: .9;
}

.otc i.icon {
    opacity: .2 !important;
}

.chooser-segment {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chooser-segment.signature{
    text-align: center;
}

.signature-buttons-container{
    display: flex;
    justify-content: space-between;
}

.ui.basic.segment.progressbar-segment{
    max-width: 1155px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
}

.ui.secondary.segment.contrast{
    background-color: white;
    transform: scale(1.05);
    z-index: 1;
    opacity: 1;
    color: black;
    box-shadow: 7px 7px 14px 0 rgba(40, 40, 60, 1);
    border: 1px solid rgba(40, 40, 60, 1);

}

.ui.segment.verification-message-info{
    background-color: #2b3444;
    color: white;
}
.ui.segment.verification-message-info i.icon{
    margin-bottom: 10px;
}

.ui.segment.verification-message-info i.icon:before{
    color: #FFA502;
}

i.orange.icon.inpay-orange {
    color: #FFA502 !important;
}

i.blue.icon.inpay-blue{
    color: #2b3444 !important;
}


.ui.segment.contrast {
    background-color: #e8e8e8;
    transform: scale(0.9);
    border: 1px solid rgb(43,52,68);
}

.ui.table thead th.panel-contrast {
    background-color: rgb(43,52,68);
    color: white;
}

.flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.verification-info-button {
    cursor: pointer;
    margin-right: 0 !important;
}

.verification-info-button:hover {
    opacity: .5 !important;
}

.noArrowsInput input::-webkit-outer-spin-button,
.noArrowsInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.noArrowsInput input[type=number] {
    -moz-appearance: textfield !important;
}

#pwdContainerLine {
    text-align: left;
}

#pwdContainer {
    padding: 0;
    margin: 0;
}

#gitInfo {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 10;
    color: lightgrey;
    padding: 0;
    margin: 0;
}

.ui.modal >  .first-welcome-modal{
    background-color: rgba(40, 40, 60, 1);
    color: #FCA50D;
}

.first-welcome-modal .ui.inverted.button:hover {
    background-color:#FCA50D;
    color: rgba(40, 40, 60, 1);
    box-shadow: none !important;
}
.first-welcome-modal .ui.inverted.button{
    box-shadow: none !important;

}
