body {
    font-size: 16px;
}

input[type='password'] {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6zwAAAgcBApocMXEAAAAASUVORK5CYII=');
    cursor: auto;
}

.ui.inverted.segment, .ui.inverted.menu {
    background-color: #2b3444;
}


.ui.inverted.segment.header {
    background: rgba(40, 40, 60, 1);
}

.subheader {
    color: white;
    background: linear-gradient(to bottom, rgba(40, 40, 60, 1) 0%, rgba(40, 49, 65, 1) 100%);
}   

.subheader .ui.container {
    background: url('./img/coin-background.webp');
    background-position: top right, top, center;
    background-repeat: no-repeat, no-repeat;
    min-height: 350px;
}

.subheader .ui.list > .item .description,
.subheader .ui.form .field > label,
.subheader .ui.secondary.pointing.menu .item {
    color: white;
}

.subheader .ui.secondary.pointing.menu .active.item,
.subheader .ui.secondary.pointing.menu .active.item:hover {
    color: white;
    border-color: orange;
}

.subheader .ui.secondary.pointing.menu .item:hover,
.subheader .ui.secondary.pointing.menu a.item:hover {
    color: white;
    border-color: orange;
}

.subheader > .menu > .active.item {
    border-color: orange;
}

.subheader .ui.secondary.pointing.menu {
    border-bottom: 0;
}

#market__overview {
    padding-top: 150px;
    margin-top: 0;
    background-image: url('./img/metka-bitcoin.png');
    background-position: top right;
    background-repeat: no-repeat;
}

.logo {
    color: white;
}

.footer.segment {
    padding: 5em 0em;
}

.buy-details .ui.grid > .orange.row,
.buy-details .ui.grid > .orange.column,
.buy-details .ui.grid > .row > .orange.column {
    background-color: #FFFFFF !important;
    color: #000000;
    font-weight: normal;
}

.buy-details .ui.grid > .red.row,
.buy-details .ui.grid > .red.column,
.buy-details .ui.grid > .row > .red.column {
    background-color: #d7e0f0 !important;
    color: #000000;
}

.buy-details .ui.grid > .yellow.row,
.buy-details .ui.grid > .yellow.column,
.buy-details .ui.grid > .row > .yellow.column {
    background-color: #eceff6 !important;
    color: #000000;
    font-weight: bold;
}

.ui.label > .icon {
    margin: 0;
}

.section-partners {
    background-color: #f6f8fc;
}

.section-services {
    background-color: white;
}

.red {
    color: red;
}

.green {
    color: green;
}

.panelMenu.ui.menu {
    font-size: 1.3rem;
}

.ui.segment.pushable {
    /*height: 100vh;*/
    /*background-color: #3C3C3B;*/
    border-radius: 0;
    border: 0;
}

.ui.loginSegment {
    width: 22rem;
    margin: auto;
}

.footer.segment {
    padding-top: 3em;
    padding-bottom: 3em;
}

.pushable > .pusher {
    background-color: white !important;
}

.ui.primary.buttons .button,
.ui.primary.button,
.ui.button {
    background-color: #FFA502;
    color: #283141;
}

.ui.primary.buttons .button:hover,
.ui.primary.button:hover,
.ui.button:hover {
    background-color: #F89200;
    color: #283141;
}

.ui.items > .item > .image:not(.ui) {
    height: 150px !important;
    width: 150px;
    display: flex;
}

.ui.items > .item > .image, .ui.items > .item > .image > img {
    max-height: 150px !important;
    align-self: center;

}

.sidebarHamburger {
    width: 70px;
}